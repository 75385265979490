<template>
  <b-container fluid v-can="'products.view'">
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col lg="12" class="profile-center">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Detalles De Producto</h4>
              </template>
              <template v-slot:body>
                <b-row align-v="center">
                  <b-form-group class="col-md-3">
                    <h6 class="custom-text">SKU:</h6>
                    <h5>{{form.sku}}</h5>
                  </b-form-group>
                  <b-form-group class="col-md-3">
                    <h6 class="custom-text">NOMBRE:</h6>
                    <h5>{{form.name}}</h5>
                  </b-form-group>
                  <b-form-group class="col-md-3">
                    <h6 class="custom-text">MARCA:</h6>
                    <h5>{{form.brand.name}}</h5>
                  </b-form-group>
                  <b-form-group class="col-md-3">
                    <h6 class="custom-text">CATEGORÍA:</h6>
                    <h5>{{form.categories}}</h5>
                  </b-form-group>
                   <b-form-group v-for="(item, index) in form.attributes" :key="index" class="col-md-3">
                    <h6 v-if="item.attr === 'color'" class="custom-text">COLOR:</h6>
                    <h6 v-if="item.attr == 'height'" class="custom-text">ALTO:</h6>
                    <h6 v-if="item.attr == 'width'" class="custom-text">ANCHO:</h6>
                    <h6 v-if="item.attr == 'length'" class="custom-text">LARGO:</h6>
                    <h6 v-if="item.attr == 'weight'" class="custom-text">PESO:</h6>
                    <h6 v-if="item.attr == 'model'" class="custom-text">MODELO:</h6>
                    <h5>{{item.value}} {{item.unit}}</h5>
                  </b-form-group>
                   <b-form-group class="col-md-12">
                    <h6 class="custom-text">COMENTARIO</h6>
                    <h5>{{form.commentary}}</h5>
                  </b-form-group>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'ShowProducts',
  async mounted () {
     this.$store.commit("SET_BREADCRUMB", this.items);
    core.index()
    this.$store.commit('run')
    const {
      image,
      sku,
      name,
      commentary,
      attributes,
      categories,
      brand
    } = await this.$store.dispatch('products/showProducts', this.id)
    this.form = { image, sku, name, commentary, attributes, brand }
    if (image === '') {
      this.form.image = image
    } else {
      this.form.image = this.preview
    }
    this.form.categories = categories[0].name
    this.$store.commit('stop')
  },
  data () {
    return {
      id: this.$route.params.id,
      form: {
        image: '',
        sku: '',
        name: '',
        categories: '',
        commentary: '',
        attributes: [],
        brand: ''
      },
      preview: require('../../../assets/images/default.png'),
      items: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          text: 'Listado De Productos',
          to: '/products/list'
        },
        {
          text: 'Ver Producto',
          active: true
        }
      ]
    }
  }
}
</script>
<style>
.custom-text {
  font-size: 0.7em !important;
  color: #a2a0a0 !important;
}
.custom-text{
  font-size: 0.7em !important;
  color: #a2a0a0 !important;
}
</style>
